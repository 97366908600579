html {
  scroll-behavior: smooth;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: block;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiContainer-root .MuiPaper-elevation1 {
  box-shadow: none;
}
.MuiContainer-root .MuiFormControl-root {
  width: 100%;
}
/* 
.MuiContainer-root .MuiTextField-root:last-child {
  margin-bottom: 0px;
} */
.MuiBox-root.MuiBox-root-10.calculate-btn {
  margin: auto;
  text-align: center;
}
.MuiBox-root.MuiBox-root-10.calculate-btn button {
  width: 100%;
  height: 56px;
  max-width: 350px;
  margin-top: 2%;
}

.swimmer-img img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 10px grey;
  /* object-fit: cover; */
}

.my_tooltip {
  position: relative;
}

.info_tooltip {
  position: absolute !important;
  right: 10px;
  top: 50%;
  transform: translate(0px, -22px);
}
.info_tooltip_title {
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: justify;
  padding: 10px;
}

.fa-info-css {
  font-size: 25px !important;
  margin-top: 12px !important;
  cursor: pointer;
}

.swimmer-sea img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.pl-100 {
  padding-left: 100px !important;
}
.mr-2 {
  margin-right: 20px !important;
}
.swimmer-detail-table .MuiTableCell-root {
  padding: 5px 13px;
}
.mt-2 {
  margin-top: 20px !important;
}
.mt-4 {
  margin-top: 40px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mt-45 {
  margin-top: 45px;
}
/* .m-tb-0{
  margin: 0px;
} */
.swim-heading {
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #2a3042;
  position: relative;
}
.swim-heading1 {
  font-size: 38px;
  font-weight: bold;
  /* text-align: center; */
  margin-top: 10px;
  margin-bottom: 40px !important;
}

.swim-content {
  display: inline-block;
}
.swimmer-detail-table .MuiTableContainer-root {
  max-height: 1000px;
}
.swimmer-sea img {
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
}
.swimmer-detail-table {
  border: 1px solid #ddd;
}
.swimmer-detail-table .MuiTableHead-root {
  position: sticky;
  background-color: #fff;
  z-index: 99;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
.swimmer-detail-table table th {
  font-weight: 700;
}
.btn-subtext {
  font-weight: 600;
  font-size: 20px;
  margin-top: 0px;
}
.back-btn {
  position: relative;
}
.back-btn button {
  position: absolute;
  top: 5px;
  margin: 0px;
}

.nav {
  background-color: #ffffff;
  padding: 10px;
  border-bottom: 2px solid #003a5d;
  box-shadow: 0 3px 6px 6px #00000012;
}
.swim-logo img {
  height: 80px;
}

.swim-content {
  /* display: inline-block; */
  color: #7e7e7e;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 21px;
  /* margin-top: 0; */
  /* max-width: 520px; */
  width: 100%;
  text-align: justify;
}

.form_main {
  background-color: #fff;
  padding: 43px 30px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  margin-bottom: 60px;
  margin-top: 36px;
}

.details {
  padding-right: 20px;
}

.info_button {
  display: flex;
  align-items: center;
}

.info_button .fa {
  padding-left: 20px;
  color: #003a5d;
}
.my_info .fa {
  position: relative;
  top: -18px;
  color: #003a5d;
}
@media (max-width: 1366px) {
  .pl-100 {
    padding-left: 85px !important;
  }
  .back-btn {
    position: unset;
  }
  .back-btn button {
    position: unset;
  }
}
@media (max-width: 1200px) {
  .pl-100 {
    padding-left: 65px !important;
  }
}
@media (max-width: 991px) {
  .swim-heading {
    font-size: 28px;
    line-height: 35px;
    font-weight: bold;
    float: none;
  }
  .swim-heading1 {
    font-size: 28px;
    line-height: 35px;
    font-weight: bold;
  }
  .swim-logo {
    text-align: center;
    float: none;
  }
  .pl-100 {
    padding-left: 12px !important;
  }
  .mt-45 {
    margin-top: 35px;
  }
}
@media (max-width: 767px) {
  .swim-heading {
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    float: none;
  }
  .swim-heading1 {
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
  }
  .swim-logo {
    text-align: center;
    float: none;
  }
  .fa-info-css {
    margin-top: 0px !important;
  }

  .swimmer-content .MuiGrid-container {
    display: block;
  }
  .my_tooltip .MuiTextField-root {
    margin-bottom: 0px;
  }
  .pl-100 {
    padding-left: 12px !important;
  }
  .swim-detail-table .swim-grid {
    width: 100%;
   
  }
  .back-btn {
    position: unset;
  }
  .back-btn button {
    position: unset;
  }
  .info_tooltip {
    transform: translate(0px, -15px);
  }
  .mt-45 {
    margin-top: 25px;
  }

  .swim-heading {
    margin-top: 10px;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    float: none;
  }

  .details {
    padding-right: 0;
  }
  .swim-content {
    display: inline-block;
    color: #7e7e7e;
    font-size: 14px;
    letter-spacing: 1px;
  }
}

/* --------------------------- new css add on 19-01-22 ----------------------------------- */
.justify-center-area{
  justify-content: center;
}
.btn-style {
  background-color: #003a5d !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  color: #fff;
  width: 280px !important;
  text-align: center;
  padding: 14px 20px;
  text-transform: uppercase;
}
.loader-height {
  margin: 0;
  height: 100vh;
}
.loader-visible {
  overflow: hidden;
}
.swim-detail-table.loader {
  /* background-color: #cccccc3d; */
  position: absolute;
  z-index: 99999;
  width: 100%;
}
.loader-height .LoadingSpinner_spinner__\+PytG:after {
  border: 6px solid #003a5d;
  border-color: #003a5d transparent #003a5d transparent;
}
/* .swim-heading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  height: 3px;
  width: 100px;
  background-color: #003a5d;
  border-radius: 10px;
} */
.swim-logo {
  text-align: center;
}
.banner-section {
  margin-top: 70px;
  justify-content: center;
}
.swim-heading {
  margin-top: 0;
  text-align: center;
}
.border-line{
  height: 5px;
  width: 100%;
  background-color: #003a5d;

}
.mb30{
  margin-bottom: 30px;
}
.input-style {
  /* border-radius: 10px; */
  /* border:1px solid #8B8B8B !important; */
  height: 55px;
  margin-bottom: 20px !important;
}
.input-style input {
  border: none;
}
.MuiButton-containedPrimary {
  background-color: #003a5d !important;
  border-radius: 10px !important;
  font-size: 16px !important;
}
.swimmer-img {
  margin-bottom: 55px;
}
.button-space {
  margin-top: 24px;
  flex-wrap: inherit !important;
}
.result-box {
  position: relative;
}
.user-name {
  position: absolute;
  /* left: 34%; */
}
.swimmer-sea {
  margin-top: 65px;
}
.MuiPaper-elevation1 {
  border: none !important;
  padding: 0 !important;
}
.text-center {
  text-align: center;
}
.form-btn button {
  width: 251px;
}
.button-space{
  margin: 40px 0 !important;
  display: flex;
  justify-content: space-between;
}
.client-logo{
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .client-logo img{
  width: 100%;
  height: auto;
  max-width: 150px;
  margin-right: 15px;
} */
.client-logo a img{
  width: 100%;
  height: auto;
  max-width: 150px;
  margin-right: 15px;
}
/* .client-logo img:last-child{
  margin-right: 0px;
}
.client-logo a img:last-child{
  margin-right: 0px;
} */
.large-btn{
  width: 315px;
}


/* .makeStyles-modal-44 .makeStyles-paper-45 {
    border: none !important;
    border-radius: 10px !important;
} */
@media (max-width: 991px) {
  .user-name {
    position: relative;
    left: 0;
  }
  .swimmer-sea {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my_info .fa {
    top: -14px;
  }
}
@media (max-width: 768) {
  .banner-section {
    margin-top: 30px;
  }
}
@media (max-width: 570px) {
  .my_info .fa {
    top: -10px;
  }
  .swimmer-img {
    margin-bottom: 30px;
  }
  .button-space {
    flex-wrap: wrap !important;
  }

  .btn-style {
    width: 200px;
  }
  .button-space {
      margin: 20px 0 !important;
  }
  /* .client-logo img {
      max-width: 99px;
      margin-right: 15px;
  } */
  .client-logo a img {
    max-width: 99px;
    margin-right: 15px;
}
}

@media (max-width: 480px) {
  .form-btn button {
    width: 100%;
  }
  .MuiTooltip-tooltip h2{
     font-size: 14px;
  }
}

@media only screen and (max-device-width: 768px) and (orientation: landscape) {
  .rotate-mode {
    display: flex !important;
  }
}

